.container {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #eee;
}

.menu {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 12rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  position: absolute;
  left: 12rem;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding: 2rem;
}

.header {
  padding: 0 0.4rem;
}
.logo {
  margin: 1rem 0.5rem;
  text-align: center;
}
.logo img {
  width: 100%;
}
