.container {
  color: #777;
  position: relative;
  width: 100%;
}

.container a:hover {
  color: white;
}
.container a {
  color: #aaa;
}

.headerLeft {
}
.headerRight {
  width: 100%;
  text-align: right;
}
.header {
  width: 100%;
  padding: 10px;
  display: flex;
}

.selected {
  border-radius: 0 1rem 1rem 0 !important;
  background: white !important;
}
.selected * {
  color: #e38900 !important;
}
